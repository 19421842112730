// @ts-ignore
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import ServiceApi from '../../../apis/serviceApi';
import ConstantDB from '../../../utils/constant/mongo-config';
import { categoryTye } from '../../../types/apps/clolor';

interface StateType {
  categories: any[];
  recentPosts: any[];
  blogSearch: string;
  sortBy: string;
  selectedPost: any;
}

const initialState = {
  categories: [],
  recentPosts: [],
  blogSearch: '',
  sortBy: 'newest',
  selectedPost: null,
};

export const CategoriesSlice = createSlice({
  name: 'Color',
  initialState,
  reducers: {
    getCategories: (state: StateType, action) => {
      state.categories = action.payload;
    },
    getCategory: (state: StateType, action) => {
      state.selectedPost = action.payload;
    },
    create: (state: StateType, action) => {
      console.log({ state, action });
      state.categories.push(action.payload);
    },
    update: (state: StateType, action) => {
      const _index_update = state.categories.findIndex((post) => post.uniqueID === action.payload.uniqueID);
      state.categories[_index_update] = action.payload;
    },
    deleteCa: (state: StateType, action) => {
      state.categories = state.categories.filter((post) => post.uniqueID !== action.payload);
    },
  },
});

export const { getCategories, getCategory, create, update, deleteCa } = CategoriesSlice.actions;

export const fetchCategories = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.find, {
      collection: ConstantDB.collect.categories,
    });
    dispatch(getCategories(response.data.documents));
  } catch (err) {
    throw new Error();
  }
};
export const createCategory = (payload: categoryTye) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.insertOne, {
      collection: ConstantDB.collect.categories,
      document: payload,
    });
    if (response)
      dispatch(create(payload));
  } catch (err) {
    throw new Error();
  }
};
export const updateCategory = (payload: categoryTye) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.updateOne, {
      collection: ConstantDB.collect.categories,
      filter: {
        uniqueID: payload.uniqueID,
      },
      update: {
        $set: {
          ...payload,
        },
      },
    });
    if (response)
      dispatch(update(payload));
  } catch (err) {
    throw new Error();
  }
};
export const deleteCategory = (uniqueID: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.deleteOne, {
      collection: ConstantDB.collect.categories,
      filter: {
        uniqueID,
      },
    });
    if (response)
      dispatch(deleteCa(uniqueID));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlogPost = (post: categoryTye) => async (dispatch: AppDispatch) => {
  try {
    console.log({ post });
    // const response = await ServiceApi.call_api(ConstantDB.action.findOne, {
    //   collection: 'news',
    //   filter: { uniqueID },
    // });
    // dispatch(getCategory(response.data.document));
    dispatch(getCategory(post));
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};
export default CategoriesSlice.reducer;
