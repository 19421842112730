import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import EcommerceReducer from './apps/eCommerce/ECommerceSlice';
import UserProfileReducer from './apps/userProfile/UserProfileSlice';
import BlogReducer from './apps/blog/BlogSlice';
import ColorReducer from './apps/colors/ColorsSlice';
import CategoryReducer from './apps/categories/CategoriesSlice';
import SliderReducer from './apps/sliders/SliderSlice';
import FeedBackReducer from './apps/feedback/FeedbackSlice';
import ImgReducer from './apps/img/ImgSlice';
import TimeKeepingReducer from './apps/time-keeping/TimeKeepingSlice';
import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    ecommerceReducer: EcommerceReducer,
    userpostsReducer: UserProfileReducer,
    blogReducer: BlogReducer,
    colorReducer: ColorReducer,
    categoryReducer: CategoryReducer,
    slidersReducer: SliderReducer,
    feedBackReducer: FeedBackReducer,
    imgReducer: ImgReducer,
    timekeepingReducer: TimeKeepingReducer,
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  ecommerceReducer: EcommerceReducer,
  userpostsReducer: UserProfileReducer,
  blogReducer: BlogReducer,
  colorReducer: ColorReducer,
  categoryReducer: CategoryReducer,
  slidersReducer: SliderReducer,
  feedBackReducer: FeedBackReducer,
  imgReducer: ImgReducer,
  timekeepingReducer: TimeKeepingReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
