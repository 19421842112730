// @ts-ignore
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import ServiceApi from '../../../apis/serviceApi';
import ConstantDB from '../../../utils/constant/mongo-config';
import { sliderType } from '../../../types/apps/clolor';

interface StateType {
  sliders: any[];
  blogSearch: string;
  sortBy: string;
}

const initialState = {
  sliders: [],
  blogSearch: '',
  sortBy: 'newest',
};

export const SliderSlice = createSlice({
  name: 'Color',
  initialState,
  reducers: {
    getSlider: (state: StateType, action) => {
      state.sliders = action.payload;
    },
    create: (state: StateType, action) => {
      console.log({ state, action });
      state.sliders.push(action.payload);
    },
    update: (state: StateType, action) => {
      const _index_update = state.sliders.findIndex((post) => post.uniqueID === action.payload.uniqueID);
      state.sliders[_index_update] = action.payload;
    },
    deleteCa: (state: StateType, action) => {
      state.sliders = state.sliders.filter((post) => post.uniqueID !== action.payload);
    },
  },
});

export const { getSlider, create, update, deleteCa } = SliderSlice.actions;

export const fetchSliders = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.find, {
      collection: ConstantDB.collect.sliders,
    });
    dispatch(getSlider(response.data.documents));
  } catch (err) {
    throw new Error();
  }
};
export const createSliders = (payload: sliderType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.insertOne, {
      collection: ConstantDB.collect.sliders,
      document: payload,
    });
    if (response)
      dispatch(create(payload));
  } catch (err) {
    throw new Error();
  }
};
export const updateSliders = (payload: sliderType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.updateOne, {
      collection: ConstantDB.collect.sliders,
      filter: {
        uniqueID: payload.uniqueID,
      },
      update: {
        $set: {
          ...payload,
        },
      },
    });
    if (response)
      dispatch(update(payload));
  } catch (err) {
    throw new Error();
  }
};
export const deleteSliders = (uniqueID: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.deleteOne, {
      collection: ConstantDB.collect.sliders,
      filter: {
        uniqueID,
      },
    });
    if (response)
      dispatch(deleteCa(uniqueID));
  } catch (err) {
    throw new Error();
  }
};
export default SliderSlice.reducer;
