// @ts-ignore
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import ServiceApi from '../../../apis/serviceApi';
import ConstantDB from '../../../utils/constant/mongo-config';
import { sliderType } from '../../../types/apps/clolor';

interface StateType {
  feedbacks: any[];
  blogSearch: string;
  sortBy: string;
}

const initialState = {
  feedbacks: [],
  blogSearch: '',
  sortBy: 'newest',
};

export const FeedbackSlice = createSlice({
  name: 'Feedback',
  initialState,
  reducers: {
    getFeedback: (state: StateType, action) => {
      state.feedbacks = action.payload;
    },
    create: (state: StateType, action) => {
      console.log({ state, action });
      state.feedbacks.push(action.payload);
    },
    update: (state: StateType, action) => {
      const _index_update = state.feedbacks.findIndex((post) => post.uniqueID === action.payload.uniqueID);
      state.feedbacks[_index_update] = action.payload;
    },
    deleteCa: (state: StateType, action) => {
      state.feedbacks = state.feedbacks.filter((post) => post.uniqueID !== action.payload);
    },
  },
});

export const { getFeedback, create, update, deleteCa } = FeedbackSlice.actions;

export const fetchFeedbacks = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.find, {
      collection: ConstantDB.collect.feedbacks,
    });
    dispatch(getFeedback(response.data.documents));
  } catch (err) {
    throw new Error();
  }
};
export const createFeedback = (payload: sliderType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.insertOne, {
      collection: ConstantDB.collect.feedbacks,
      document: payload,
    });
    if (response)
      dispatch(create(payload));
  } catch (err) {
    throw new Error();
  }
};
export const updateFeedback = (payload: sliderType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.updateOne, {
      collection: ConstantDB.collect.feedbacks,
      filter: {
        uniqueID: payload.uniqueID,
      },
      update: {
        $set: {
          ...payload,
        },
      },
    });
    if (response)
      dispatch(update(payload));
  } catch (err) {
    throw new Error();
  }
};
export const deleteFeedback = (uniqueID: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.deleteOne, {
      collection: ConstantDB.collect.feedbacks,
      filter: {
        uniqueID,
      },
    });
    if (response)
      dispatch(deleteCa(uniqueID));
  } catch (err) {
    throw new Error();
  }
};
export default FeedbackSlice.reducer;
