// @ts-ignore
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import ServiceApi from '../../../apis/serviceApi';
import ConstantDB from '../../../utils/constant/mongo-config';
import { sliderType } from '../../../types/apps/clolor';

interface StateType {
  imgs: any[];
  blogSearch: string;
  sortBy: string;
}

const initialState = {
  imgs: [],
  blogSearch: '',
  sortBy: 'newest',
};

export const ImgSlice = createSlice({
  name: 'Imgs',
  initialState,
  reducers: {
    getImgs: (state: StateType, action) => {
      state.imgs = action.payload;
    },
    create: (state: StateType, action) => {
      console.log({ state, action });
      state.imgs.push(action.payload);
    },
    update: (state: StateType, action) => {
      const _index_update = state.imgs.findIndex((post) => post.uniqueID === action.payload.uniqueID);
      state.imgs[_index_update] = action.payload;
    },
    deleteCa: (state: StateType, action) => {
      state.imgs = state.imgs.filter((post) => post.uniqueID !== action.payload);
    },
  },
});

export const { getImgs, create, update, deleteCa } = ImgSlice.actions;

export const fetchImgs = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.find, {
      collection: ConstantDB.collect.noneEnd,
    });
    dispatch(getImgs(response.data.documents));
  } catch (err) {
    throw new Error();
  }
};
export const createImg = (payload: sliderType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.insertOne, {
      collection: ConstantDB.collect.noneEnd,
      document: payload,
    });
    if (response)
      dispatch(create(payload));
  } catch (err) {
    throw new Error();
  }
};
export const updateImg = (payload: sliderType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.updateOne, {
      collection: ConstantDB.collect.noneEnd,
      filter: {
        uniqueID: payload.uniqueID,
      },
      update: {
        $set: {
          ...payload,
        },
      },
    });
    if (response)
      dispatch(update(payload));
  } catch (err) {
    throw new Error();
  }
};
export const deleteImg = (uniqueID: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.deleteOne, {
      collection: ConstantDB.collect.noneEnd,
      filter: {
        uniqueID,
      },
    });
    if (response)
      dispatch(deleteCa(uniqueID));
  } catch (err) {
    throw new Error();
  }
};
export default ImgSlice.reducer;
