import { createContext, useEffect, useReducer } from 'react';

// utils
// @ts-ignore
import axios from 'src/utils/axios';
import { isValidToken, setSession } from './Jwt';
import ConstantDB from '../../utils/constant/mongo-config';
import ServiceApi from '../../apis/serviceApi';
import { userType } from '../../types/apps/users';
import { AppDispatch } from '../../store/Store';
import { getPosts } from '../../store/apps/userProfile/UserProfileSlice';

// ----------------------------------------------------------------------
export interface InitialStateType {
  isAuthenticated: boolean;
  isInitialized?: boolean;
  user?: any | null | undefined;
  token: string | null;
}

const initialState: InitialStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null,
};

const handlers: any = {
  INITIALIZE: (state: InitialStateType, action: any) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: InitialStateType, action: any) => {
    const { user } = action.payload;
    console.log({ user });
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: InitialStateType) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: InitialStateType, action: any) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE: (state: InitialStateType, action: any) => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
};

const reducer = (state: InitialStateType, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<any | null>({
  ...initialState,
  platform: 'JWT',
  signup: () => Promise.resolve(),
  signin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  update_info: () => Promise.resolve(),
});

function AuthProvider({ children }: { children: React.ReactElement }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const user: userType = JSON.parse(window.localStorage.getItem('user') as string);
        if (accessToken && isValidToken(accessToken) && user) {
          setSession(accessToken);
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          localStorage.clear();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signin = async (email: string, password: string) => {
    const response = await ServiceApi.call_api(ConstantDB.action.findOne, {
      collection: 'users',
      projection: {},
      filter: {
        email,
        password,
      },
    });
    if (response.data.document) {
      // @ts-ignore
      localStorage.setItem(response.data.document.role ? 'isAdmin' : 'isMember', true);
      localStorage.setItem('user', JSON.stringify(response.data.document));
      // setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: response.data.document,
        },
      });
    }

  };

  const signup = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  const update_info = async (user: userType) => {
    console.log(user);
    try {
      await ServiceApi.call_api(ConstantDB.action.updateOne, {
        collection: ConstantDB.collect.users,
        filter: {
          email: user?.email,
        },
        update: {
          $set: {
            ...user,
          },
        },
      });
      dispatch({
        type: 'UPDATE', payload: {
          user,
        },
      });
    } catch (err: any) {
      console.log(err);
      throw new Error(err);
    }
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signin,
        logout,
        signup,
        update_info,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
