import axios from 'axios';

const axiosServices = axios.create(
  {
    baseURL: 'https://ap-southeast-1.aws.data.mongodb-api.com/app/data-ywubf/endpoint/data/v1/action',
  },
);

// interceptor for http
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    switch (error.response.status) {
      case 401:
        window.location.href = 'auth/login';
        break;
      default:
        break;
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  },
);

export default axiosServices;
 