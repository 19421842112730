// @ts-ignore
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import ServiceApi from '../../../apis/serviceApi';
import ConstantDB from '../../../utils/constant/mongo-config';
import { BlogPostType } from '../../../types/apps/blog';
import { colorTye } from '../../../types/apps/clolor';

interface StateType {
  colors: any[];
  recentPosts: any[];
  blogSearch: string;
  sortBy: string;
  selectedPost: any;
}

const initialState = {
  colors: [],
  recentPosts: [],
  blogSearch: '',
  sortBy: 'newest',
  selectedPost: null,
};

export const ColorsSlice = createSlice({
  name: 'Color',
  initialState,
  reducers: {
    getColors: (state: StateType, action) => {
      state.colors = action.payload;
    },
    getPost: (state: StateType, action) => {
      state.selectedPost = action.payload;
    },
    createColor: (state: StateType, action) => {
      console.log({ state, action });
      state.colors.push(action.payload);
    },
    updateColor: (state: StateType, action) => {
      const _index_update = state.colors.findIndex((post) => post.uniqueID === action.payload.uniqueID);
      state.colors[_index_update] = action.payload;
    },
    deleteColor: (state: StateType, action) => {
      console.log({ blogPost: state.colors, action });
      state.colors = state.colors.filter((post) => post.uniqueID !== action.payload);
    },
  },
});

export const { getColors, getPost, createColor, updateColor, deleteColor } = ColorsSlice.actions;

export const fetchColors = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.find, {
      collection: ConstantDB.collect.colors,
    });
    dispatch(getColors(response.data.documents));
  } catch (err) {
    throw new Error();
  }
};
export const createColour = (payload: colorTye) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.insertOne, {
      collection: ConstantDB.collect.colors,
      document: payload,
    });
    if (response)
      dispatch(createColor(payload));
  } catch (err) {
    throw new Error();
  }
};
export const updateColour = (payload: colorTye) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.updateOne, {
      collection: ConstantDB.collect.colors,
      filter: {
        uniqueID: payload.uniqueID,
      },
      update: {
        $set: {
          ...payload,
        },
      },
    });
    if (response)
      dispatch(updateColor(payload));
  } catch (err) {
    throw new Error();
  }
};
export const deleteColour = (uniqueID: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.deleteOne, {
      collection: ConstantDB.collect.colors,
      filter: {
        uniqueID,
      },
    });
    if (response)
      dispatch(deleteColor(uniqueID));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlogPost = (post: colorTye) => async (dispatch: AppDispatch) => {
  try {
    console.log({ post });
    // const response = await ServiceApi.call_api(ConstantDB.action.findOne, {
    //   collection: 'news',
    //   filter: { uniqueID },
    // });
    // dispatch(getPost(response.data.document));
    dispatch(getPost(post));
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};
export default ColorsSlice.reducer;
