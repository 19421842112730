// @ts-ignore
import axiosServices from '../utils/axios';
import ConstantDB from '../utils/constant/mongo-config';
import * as Realm from 'realm-web';

const service = {
  call_api: (action = ConstantDB.action.find, props: any, token = localStorage.getItem('accessToken')) => {
    const data = JSON.stringify({
      database: ConstantDB.database,
      dataSource: ConstantDB.dataSource,
      ...props,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    return axiosServices.post(`${action}`, data, config);
  },
  uploadFile: (body: any) => {
    return axiosServices.post('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
      ...body,
      upload_preset: ConstantDB.cloudinary.upload_preset,
    });
  },
  getToken: async () => {
    const app = new Realm.App({ id: ConstantDB.id_mongo });

    // @ts-ignore
    async function loginEmailPassword(email, password) {
      const credentials = Realm.Credentials.emailPassword(email, password);

      return await app.logIn(credentials);
    }

    const user = await loginEmailPassword(ConstantDB.email, ConstantDB.password);

    // @ts-ignore
    localStorage.setItem('accessToken', user.accessToken);

    return user.accessToken;

  },
};
export default service;
