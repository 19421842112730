// @ts-ignore
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import ServiceApi from '../../../apis/serviceApi';
import ConstantDB from '../../../utils/constant/mongo-config';
import { timekeepingType } from '../../../types/apps/clolor';

interface StateType {
  timekeeping: any[];
  sortBy: string;
  timekeepingByFilter: timekeepingType[];
}

const initialState = {
  timekeeping: [],
  sortBy: 'newest',
  timekeepingByFilter: [],
};

export const TimeKeepingSlice = createSlice({
  name: 'timekeeping',
  initialState,
  reducers: {
    get: (state: StateType, action) => {
      state.timekeeping = action.payload;
    },
    create: (state: StateType, action) => {
      state.timekeeping.push(action.payload);
    },
    update: (state: StateType, action) => {
      const _index_update = state.timekeeping.findIndex((post) => post.uniqueID === action.payload.uniqueID);
      state.timekeeping[_index_update] = action.payload;
    },
    delete_: (state: StateType, action) => {
      state.timekeeping = state.timekeeping.filter((time) => time.uniqueID !== action.payload);
    },
    filterByUser: (state: StateType, action) => {
      if (action.payload) {
        const { userId, month, year } = action.payload;
        let _result = state.timekeeping;
        if (userId) {
          _result = _result.filter((time) => time.userId === userId);
        }
        if (month && year) {
          _result = _result.filter((time) => Number(time.month) === month && Number(time.year) === year);
        }
        state.timekeepingByFilter = _result;
      } else {
        state.timekeepingByFilter = state.timekeeping;
      }
    },
  },
});

export const { get, create, update, delete_, filterByUser } = TimeKeepingSlice.actions;

export const fetchTimeKeeping = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.find, {
      collection: ConstantDB.collect.timeKeeping,
    });
    dispatch(get(response.data.documents));
  } catch (err) {
    throw new Error();
  }
};
export const createTimeKeeping = (payload: timekeepingType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.insertOne, {
      collection: ConstantDB.collect.timeKeeping,
      document: payload,
    });
    if (response)
      dispatch(create(payload));
  } catch (err) {
    throw new Error();
  }
};
export const updateTimeKeeping = (payload: timekeepingType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.updateOne, {
      collection: ConstantDB.collect.timeKeeping,
      filter: {
        uniqueID: payload.uniqueID,
      },
      update: {
        $set: {
          ...payload,
        },
      },
    });
    if (response)
      dispatch(update(payload));
  } catch (err) {
    throw new Error();
  }
};
export const deleteTimeKeeping = (uniqueID: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.deleteOne, {
      collection: ConstantDB.collect.timeKeeping,
      filter: {
        uniqueID,
      },
    });
    if (response)
      dispatch(delete_(uniqueID));
  } catch (err) {
    throw new Error();
  }
};
export const filterBy_user = (filter: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(filterByUser(filter));
  } catch (err) {
    throw new Error();
  }
};

export default TimeKeepingSlice.reducer;
