// @ts-ignore
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import ServiceApi from '../../../apis/serviceApi';
import ConstantDB from '../../../utils/constant/mongo-config';
import { BlogPostType } from '../../../types/apps/blog';

interface StateType {
  blogposts: any[];
  recentPosts: any[];
  blogSearch: string;
  sortBy: string;
  selectedPost: any;
}

const initialState = {
  blogposts: [],
  recentPosts: [],
  blogSearch: '',
  sortBy: 'newest',
  selectedPost: null,
};

export const BlogSlice = createSlice({
  name: 'Blog',
  initialState,
  reducers: {
    getPosts: (state: StateType, action) => {
      state.blogposts = action.payload;
    },
    getPost: (state: StateType, action) => {
      state.selectedPost = action.payload;
    },
    createPost: (state: StateType, action) => {
      console.log({ state, action });
      state.blogposts.push(action.payload);
    },
    updatePost: (state: StateType, action) => {
      const _index_update = state.blogposts.findIndex((post) => post.uniqueID === action.payload.uniqueID);
      state.blogposts[_index_update] = action.payload;
    },
    deletePost: (state: StateType, action) => {
      console.log({ blogPost: state.blogposts, action });
      state.blogposts = state.blogposts.filter((post) => post.uniqueID !== action.payload);
    },
  },
});

export const { getPosts, getPost, createPost, updatePost, deletePost } = BlogSlice.actions;

export const fetchBlogPosts = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.find, {
      collection: ConstantDB.collect.news,
    });
    dispatch(getPosts(response.data.documents));
  } catch (err) {
    throw new Error();
  }
};
export const createBlogPost = (payload: BlogPostType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.insertOne, {
      collection: ConstantDB.collect.news,
      document: payload,
    });
    if (response)
      dispatch(createPost(payload));
  } catch (err) {
    throw new Error();
  }
};
export const updateBlogPost = (payload: BlogPostType) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.updateOne, {
      collection: ConstantDB.collect.news,
      filter: {
        uniqueID: payload.uniqueID,
      },
      update: {
        $set: {
          ...payload,
          slug: payload.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') + '-' + Math.floor(Math.random() * 10000000000000),
        },
      },
    });
    if (response)
      dispatch(updatePost(payload));
  } catch (err) {
    throw new Error();
  }
};
export const deleteBlogPost = (uniqueID: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await ServiceApi.call_api(ConstantDB.action.deleteOne, {
      collection: ConstantDB.collect.news,
      filter: {
        uniqueID,
      },
    });
    if (response)
      dispatch(deletePost(uniqueID));
  } catch (err) {
    throw new Error();
  }
};
export const addComment = (postId: number, comment: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post('/api/data/blog/post/add', { postId, comment });
    dispatch(getPosts(response.data.posts));
  } catch (err: any) {
    throw new Error(err);
  }
};
export const fetchBlogPost = (post: BlogPostType) => async (dispatch: AppDispatch) => {
  try {
    console.log({ post });
    // const response = await ServiceApi.call_api(ConstantDB.action.findOne, {
    //   collection: 'news',
    //   filter: { uniqueID },
    // });
    // dispatch(getPost(response.data.document));
    dispatch(getPost(post));
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};
export default BlogSlice.reducer;
