const ConstantDB = {
  action: {
    find: 'find',
    findOne: 'findOne',
    insertOne: 'insertOne',
    updateOne: 'updateOne',
    deleteOne: 'deleteOne',
  },
  crud: {
    create: 'create',
    delete: 'delete',
    update: 'update',
    view: 'view',
  },
  collect: {
    products: 'products',
    users: 'users',
    categories: 'categories',
    news: 'news',
    colors: 'colors',
    sliders: 'sliders',
    timeKeeping: 'timeKeeping',
    feedbacks: 'feedbacks',
    titles: 'titles',
    noneEnd: 'noneEnd',

  },
  database: 'test',
  dataSource: 'Cluster0',
  id_mongo: 'data-ywubf',
  email: 'sunhaircompany',
  password: 'nRye6PfMkJ6XOcMO',
  upload_preset: 'upload_preset',
  cloudinary: {
    upload_preset: 'kx4mzert',
    url_cloud: 'https://api.cloudinary.com/v1_1/dfjgtdsbf/image',
    action: {
      upload: 'upload',
    },
  },
  platform: [
    { code: 'Instagram', name: 'Instagram' },
    { code: 'Facebook', name: 'Facebook' },
    { code: 'Tiktok', name: 'Tiktok' },
    { code: 'Youtube', name: 'Youtube' },
    { code: 'Google', name: 'Google' },
    { code: 'Zalo', name: 'Zalo' },
    { code: 'X', name: 'X' },
    { code: 'Pinterest', name: 'Pinterest' },
    { code: 'Thread', name: 'Thread' },
    { code: 'Alibaba', name: 'Alibaba' },
    { code: 'Other', name: 'Khác' },
  ],
};

export default ConstantDB;